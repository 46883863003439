/*
Theme Name: Custom Molding
Author: Jeremy Fairbank
Description: Custom Molding
Version: 1.1
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: custommolding
*/
/* line 4, ../../scss/divider.scss */
.divider-large {
  background: url(http://www.custommolding.com/wp-content/themes/custommolding/assets/images/divider-large.png) top center no-repeat;
  height: 27px;
  margin: 25px auto 35px auto;
}
@media (max-width: 768px) {
  /* line 4, ../../scss/divider.scss */
  .divider-large {
    background: none;
    background-color: #ddd;
    height: 5px;
  }
}

/* line 6, ../../scss/shortcodes.scss */
.cm-feature-text {
  text-align: center;
}
/* line 9, ../../scss/shortcodes.scss */
.cm-feature-text h5 {
  font-size: 15px;
  font-weight: normal;
}

/* line 17, ../../scss/shortcodes.scss */
.cm-feature-box {
  background-color: #d5d5d5;
  *zoom: 1;
  filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFEEEEEE', endColorstr='#FFD5D5D5');
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2Q1ZDVkNSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #eeeeee), color-stop(100%, #d5d5d5));
  background-image: -webkit-linear-gradient(top, #eeeeee 0%, #d5d5d5 100%);
  background-image: -moz-linear-gradient(top, #eeeeee 0%, #d5d5d5 100%);
  background-image: -o-linear-gradient(top, #eeeeee 0%, #d5d5d5 100%);
  background-image: linear-gradient(top, #eeeeee 0%, #d5d5d5 100%);
  border-bottom: 1px solid #c6c6c6;
  border-left: 1px solid #d1d1d1;
  border-right: 1px solid #d1d1d1;
  border-top: 1px solid #d1d1d1;
  color: #808080;
  margin: 35px 0;
  padding: 25px 30px;
}
/* line 37, ../../scss/shortcodes.scss */
.cm-feature-box .cm-col-header {
  font-size: 28px;
}

/* line 44, ../../scss/shortcodes.scss */
.cm-col-header {
  color: #0383d7;
  font-size: 19px;
  margin-bottom: 9px;
}

/* line 53, ../../scss/shortcodes.scss */
.cm-checklist {
  list-style: none;
  padding-left: 0;
}
/* line 57, ../../scss/shortcodes.scss */
.cm-checklist li {
  background: url(http://www.custommolding.com/wp-content/themes/custommolding/assets/images/checkmark.png) 0px 4px no-repeat;
  margin-left: 0;
  padding: 0 0 2px 20px;
  text-decoration: underline;
}

/* line 67, ../../scss/shortcodes.scss */
.cm-center-slider {
  margin-left: auto;
  margin-right: auto;
}

/* line 18, ../../scss/style.scss */
body {
  background: #ececec url(http://www.custommolding.com/wp-content/themes/custommolding/assets/images/background.png);
  color: #4e4e4e;
  font-family: 'Lucida Sans Unicode', 'Lucida Grande', Arial, sans-serif;
}

/* line 11, ../../scss/header.scss */
#main-header {
  background: #2d627b url(http://www.custommolding.com/wp-content/themes/custommolding/assets/images/header-bg.png) top left repeat-x;
  font-family: 'PT Sans', arial, sans-serif;
}

/* line 21, ../../scss/header.scss */
#main-header .brand-and-contact {
  margin-bottom: 9px;
}
/* line 27, ../../scss/header.scss */
#main-header .brand {
  padding-top: 31px;
}
/* line 30, ../../scss/header.scss */
#main-header .brand a {
  background: url(http://www.custommolding.com/wp-content/themes/custommolding/assets/images/logo.png) top left no-repeat;
  display: block;
  height: 60px;
  text-indent: -9999px;
}
@media all and (-webkit-min-device-pixel-ratio: 1.5) {
  /* line 30, ../../scss/header.scss */
  #main-header .brand a {
    background: url(http://www.custommolding.com/wp-content/themes/custommolding/assets/images/logo@2x.png) top left no-repeat;
    background-size: 285px 50px;
  }
}
/* line 46, ../../scss/header.scss */
#main-header .social {
  margin-top: 65px;
}
/* line 52, ../../scss/header.scss */
#main-header .contact {
  color: #cbcbcb;
  padding-top: 26px;
}
/* line 56, ../../scss/header.scss */
#main-header .contact a {
  color: #cbcbcb;
}
/* line 60, ../../scss/header.scss */
#main-header .contact .contact-info {
  margin-bottom: 10px;
}
/* line 64, ../../scss/header.scss */
#main-header .contact .request-quote a {
  *zoom: 1;
  filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFE9E9E9', endColorstr='#FFD0D0D0');
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2U5ZTllOSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2QwZDBkMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #e9e9e9), color-stop(100%, #d0d0d0));
  background-image: -webkit-linear-gradient(top, #e9e9e9 0%, #d0d0d0 100%);
  background-image: -moz-linear-gradient(top, #e9e9e9 0%, #d0d0d0 100%);
  background-image: -o-linear-gradient(top, #e9e9e9 0%, #d0d0d0 100%);
  background-image: linear-gradient(top, #e9e9e9 0%, #d0d0d0 100%);
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  color: #4c4c4c;
  font-size: 18px;
  font-style: italic;
  font-weight: bold;
  padding: 8px 16px;
}
/* line 75, ../../scss/header.scss */
#main-header .contact .request-quote a:hover {
  *zoom: 1;
  filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFECECEC', endColorstr='#FFD7D7D7');
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VjZWNlYyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2Q3ZDdkNyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #ececec), color-stop(100%, #d7d7d7));
  background-image: -webkit-linear-gradient(top, #ececec 0%, #d7d7d7 100%);
  background-image: -moz-linear-gradient(top, #ececec 0%, #d7d7d7 100%);
  background-image: -o-linear-gradient(top, #ececec 0%, #d7d7d7 100%);
  background-image: linear-gradient(top, #ececec 0%, #d7d7d7 100%);
  color: #5b5b5b;
}
/* line 86, ../../scss/header.scss */
#main-header .navbar-custom-molding {
  background-color: #d6d6d6;
  *zoom: 1;
  filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFD6D6D6', endColorstr='#FFB3B3B3');
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIxNSUiIHN0b3AtY29sb3I9IiNkNmQ2ZDYiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNiM2IzYjMiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=');
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(15%, #d6d6d6), color-stop(100%, #b3b3b3));
  background-image: -webkit-linear-gradient(top, #d6d6d6 15%, #b3b3b3 100%);
  background-image: -moz-linear-gradient(top, #d6d6d6 15%, #b3b3b3 100%);
  background-image: -o-linear-gradient(top, #d6d6d6 15%, #b3b3b3 100%);
  background-image: linear-gradient(top, #d6d6d6 15%, #b3b3b3 100%);
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  min-height: 40px;
}
/* line 111, ../../scss/header.scss */
#main-header .navbar-custom-molding .navbar-brand {
  color: #5a5a5a;
  padding-bottom: 9px;
  padding-top: 10px;
}
@media (min-width: 768px) {
  /* line 111, ../../scss/header.scss */
  #main-header .navbar-custom-molding .navbar-brand {
    display: none;
  }
}
/* line 123, ../../scss/header.scss */
#main-header .navbar-custom-molding .navbar-toggle {
  padding-bottom: 2px;
  padding-top: 5px;
}
/* line 128, ../../scss/header.scss */
#main-header .navbar-custom-molding .navbar-toggle .icon-bar {
  background-color: #333;
}
/* line 133, ../../scss/header.scss */
#main-header .navbar-custom-molding .navbar-collapse {
  border-top: 0px none;
}
@media (min-width: 768px) {
  /* line 140, ../../scss/header.scss */
  #main-header .navbar-custom-molding .nav > li:before {
    background-color: #bbb;
    *zoom: 1;
    filter: progid:DXImageTransform.Microsoft.gradient(gradientType=1, startColorstr='#FFBBBBBB', endColorstr='#FFDDDDDD');
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iNTAlIiB4Mj0iMTAwJSIgeTI9IjUwJSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2JiYmJiYiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2RkZGRkZCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0%, #bbbbbb), color-stop(100%, #dddddd));
    background-image: -webkit-linear-gradient(left, #bbbbbb 0%, #dddddd 100%);
    background-image: -moz-linear-gradient(left, #bbbbbb 0%, #dddddd 100%);
    background-image: -o-linear-gradient(left, #bbbbbb 0%, #dddddd 100%);
    background-image: linear-gradient(left, #bbbbbb 0%, #dddddd 100%);
    content: "";
    font-size: 13px;
    float: left;
    height: 39px;
    width: 2px;
  }
}
/* line 166, ../../scss/header.scss */
#main-header .navbar-custom-molding .nav > li:first-child:before {
  display: none;
}
/* line 172, ../../scss/header.scss */
#main-header .navbar-custom-molding .nav > li > a {
  color: #5a5a5a;
  font-weight: bold;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  text-transform: uppercase;
}
/* line 181, ../../scss/header.scss */
#main-header .navbar-custom-molding .nav > li > a:hover {
  background-color: #cacaca;
  *zoom: 1;
  filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFCACACA', endColorstr='#FFA8A8A8');
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSI+PHN0b3Agb2Zmc2V0PSIxNSUiIHN0b3AtY29sb3I9IiNjYWNhY2EiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNhOGE4YTgiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=');
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(15%, #cacaca), color-stop(100%, #a8a8a8));
  background-image: -webkit-linear-gradient(top, #cacaca 15%, #a8a8a8 100%);
  background-image: -moz-linear-gradient(top, #cacaca 15%, #a8a8a8 100%);
  background-image: -o-linear-gradient(top, #cacaca 15%, #a8a8a8 100%);
  background-image: linear-gradient(top, #cacaca 15%, #a8a8a8 100%);
}

/* line 6, ../../scss/footer.scss */
#main-footer {
  background: #164668 url(http://www.custommolding.com/wp-content/themes/custommolding/assets/images/footer_bg.png) top left repeat-x;
  border-top: 4px solid #c5c5c5;
  color: #bbbbbb;
  font-size: 12px;
  overflow: hidden;
}
@media (max-width: 768px) {
  /* line 6, ../../scss/footer.scss */
  #main-footer {
    background-image: none;
  }
}
/* line 28, ../../scss/footer.scss */
#main-footer a {
  color: #bbbbbb;
}
/* line 32, ../../scss/footer.scss */
#main-footer .container {

  padding-bottom: 25px;
  padding-top: 25px;
}
@media (max-width: 768px) {
  /* line 32, ../../scss/footer.scss */
  #main-footer .container {
    background-image: none;
  }
}
/* line 44, ../../scss/footer.scss */
#main-footer .cm-footer-column-header {
  border-bottom: 1px solid #989898;
  color: #fff;
  font-size: 18px;
  margin: 15px 0 10px 0;
  padding: 0 0 6px;
  text-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
}
/* line 58, ../../scss/footer.scss */
#main-footer .footer-column .nav.cm-checklist li {
  background-position: 4px 14px;
}
/* line 62, ../../scss/footer.scss */
#main-footer .footer-column .nav a {
  color: #fff;
}
/* line 12, ../../scss/footer.scss */
#main-footer .footer-column .nav a:hover {
  color: #333333;
}
/* line 70, ../../scss/footer.scss */
#main-footer .cm-footer-contact-us-form .gform_heading {
  margin-bottom: 0;
}
/* line 75, ../../scss/footer.scss */
#main-footer .cm-footer-contact-us-form input[type="text"] {
  padding: 1px 2px;
}
/* line 80, ../../scss/footer.scss */
#main-footer .cm-footer-contact-us-form textarea.large {
  height: 90px;
}
/* line 87, ../../scss/footer.scss */
#main-footer .footer-base {
  margin-top: 15px;
}
/* line 91, ../../scss/footer.scss */
#main-footer .copyright {
  margin-top: 4px;
}
/* line 97, ../../scss/footer.scss */
#main-footer .cm-footer-base-nav li {
  float: left;
}
/* line 100, ../../scss/footer.scss */
#main-footer .cm-footer-base-nav li a {
  color: #fff;
  padding: 4px 8px;
}
/* line 12, ../../scss/footer.scss */
#main-footer .cm-footer-base-nav li a:hover {
  color: #333333;
}

/* line 30, ../../scss/style.scss */
#main {
  background: url(http://www.custommolding.com/wp-content/themes/custommolding/assets/images/content-bg.png) top left repeat-x;
}
/* line 33, ../../scss/style.scss */
#main .container {
  padding-top: 30px;
}

.gf_progressbar p{
  display:none;
}
body .flexslider{border:0}

@media (max-width: 991px){
  #main-header .container{
    position: relative;
  }
  #main-header .contact{
    position: absolute;
  right:0; top:30px;padding-top:0}
  #main-header .social{
    margin-top:0
  }
  #main .container{
    padding-top:5px;
  }
}

@media(max-width:490px){
  #main-header .contact, #main-header .contact .pull-right{
    position: static;
    width:100%
    }
  #main-header .social{
    display: none;
  }
}
